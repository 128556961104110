
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";
.contentStyle {
    h2 {
        text-transform: capitalize;
        color: $cpap-main-color;
        font-weight: bold;
        font-size: 19px;
        line-height: 28.5px;
        @include lg {
            font-size: 25px;
            line-height: 37.5px;
        }
    }
    > span {
        width: 104px;
        display: inline-block;
        height: 2px;
        background-color: $cpap-main-color;
    }
    p {
        font-size: 16px;
        line-height: 28px;
        @include lg {
            font-size: 18px;
            line-height: 32px;
        }
    }
    p:first-of-type {
        margin-top: 20px;
        @include lg {
            margin-top: 30px;
        }
    }
}
.iconsStyle {
    display: flex;
    flex-wrap: wrap;
    width: 71%;
    gap: 30px;
    margin-left: auto;
    margin-right: auto;
    @include sm {
        width: auto;
        gap: 0;
    }

    justify-content: space-around;
    margin-top: 61px;
    h3 {
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        margin-top: 30px;
    }
    div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        align-items: center;
    }
}
